import React from 'react'
import { array, func, oneOf, string } from 'prop-types'

import XCircle from '@creditas/icons/dist/action/XCircle'
import AlertTriangle from '@creditas/icons/dist/alert/AlertTriangle'
import CheckCircle from '@creditas/icons/dist/notification/CheckCircle'

import {
  HelperText,
  IconWrapper,
  RadioInputField,
  Wrapper,
} from './Radio.style'

const propTypes = {
  'data-testid': string,
  helperText: string,
  id: string,
  label: string,
  name: string,
  onChange: func,
  options: array,
  selectedOptions: array,
  template: string,
  variant: oneOf(['success', 'warning', 'danger', 'default']),
}

const defaultProps = {
  id: 'radio',
  options: [],
  selectedOptions: [],
  'data-testid': 'radio',
}

const iconVariants = {
  danger: AlertTriangle,
  success: CheckCircle,
  warning: XCircle,
}

const Radio = React.forwardRef((props, ref) => {
  const {
    'data-testid': dataTestid,
    helperText,
    id,
    label,
    name,
    onChange,
    options,
    selectedOptions,
    template,
    variant,
  } = props

  const radioOptions =
    options?.map(({ label: radioLabel, value }) => ({
      id: `${id}-${name}-${value}`,
      text: radioLabel,
      value,
    })) || []

  const selectedOption = selectedOptions && selectedOptions[0]
  const radioValue = selectedOption || ''
  const StatusIcon = iconVariants[variant]

  const radioProps =
    template === 'Template03'
      ? {}
      : {
          boxed: true,
          fullWidh: true,
          options: radioOptions,
          type: 'radio',
        }

  return (
    <Wrapper as="fieldset" data-testid={dataTestid}>
      {StatusIcon && (
        <IconWrapper>
          <StatusIcon
            data-testid={`${dataTestid}-status-icon`}
            color={variant}
          />
        </IconWrapper>
      )}

      <RadioInputField
        label={label}
        name={name}
        onChange={onChange}
        value={radioValue}
        ref={ref}
        {...radioProps}
      />
      {helperText && (
        <HelperText variant="supportForm" color={variant}>
          {helperText}
        </HelperText>
      )}
    </Wrapper>
  )
})

Radio.displayName = 'Radio'
Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default Radio
