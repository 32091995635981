import { RadioInputField as CreditasRadioInputField } from '@creditas/input/dist/RadioInputField'
import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'

const Wrapper = styled.div`
  position: relative;
`

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const RadioInputField = styled(CreditasRadioInputField)`
  fieldset {
    &:focus {
      outline: none;
    }

    > label {
      font-weight: 300;
      padding-right: 20px;
    }
  }
`

const HelperText = styled(Typography)`
  margin-top: -28px;
  margin-bottom: 14px;
`

export { Wrapper, LegendWrapper, IconWrapper, RadioInputField, HelperText }
